import React, {useContext, useState} from "react"
import {Button, ButtonGroup, Loader} from "rsuite"
import {Container} from "aurelia-framework"
import {useInterfaceImplementationsQuery} from "../../../store/api"
import {selectEntities} from "../../../dialog/universal-entity-select"
import {useDispatch} from "../../../store/store";
import {addAssignments} from "../../store/assign-slice";
import AureliaContext from "../../../utilities/aurelia-context";
import {MongoDataLoader} from "../../../table/loader/mongo-data-loader";

export default function LinkButtons({title, organization}) {

    const dataLoader = Container.instance.get(MongoDataLoader);

    const dispatch = useDispatch()
    const {i18n} = useContext(AureliaContext)
    const {isLoading, data: models} = useInterfaceImplementationsQuery("payment/payable")
    const [linking, setLinking] = useState(false)

    async function link(combinedId) {
        const account = "accounting/ledger-account" === combinedId

        setLinking(true)

        try {
            //For pay backs we need to assign to paid receipts
            const {wasCancelled, output} = await selectEntities(
                combinedId,
                account ? {
                    type: {$nin: ["DEBTOR", "CREDITOR"]},
                    organization,
                } : {
                    openAmount: {$exists: true}
                },
                {title}
            )

            if (wasCancelled || !output.length) {
                return
            }

            const result = account ? {items: []} : await dataLoader.getData('payment/payment', {
                conditions: {
                    reference: {
                        $in: output.map(({id, modelId}) => ({id, modelId}))
                    },
                    state: {
                        $nin: [
                            'completed',
                            'partially_refunded',
                            'refunded',
                        ]
                    }
                }
            })

            const payload = [];

            for (const item of output) {
                const openPayments = [];

                for (const payment of result.items) {
                    if (payment.reference.id === item.id) {
                        openPayments.push(payment);
                    }
                }

                payload.push({...item, openPayments})
            }

            dispatch(addAssignments(payload))
        } finally {
            setLinking(false)
        }
    }

    if (isLoading) {
        return <Loader/>
    }

    return (
        <ButtonGroup size="sm">
            <Button disabled={true} color={"green"}>↶ Hinzufügen:</Button>

            {[
                ...models,
                {
                    combinedId: "accounting/ledger-account",
                    label: "accounting.label.ledgerAccount"
                }
            ].map(({combinedId, label}) => (
                <Button key={combinedId} loading={linking} onClick={() => link(combinedId)}>
                    {i18n.tr(label)}
                </Button>
            ))}
        </ButtonGroup>
    )
}
